<template v-slot>
  <div ref="services">
    <div class="flex-grow-1">
        <v-container>
          <v-row>
            <v-col class="d-flex flex-column" cols="12">
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="filterArr(objDoctorDepartment)"
                  v-model="selectedClinic"
                  label="Выберите клинику"
                  outlined
                  flat
                  dense
                  @input="dataFilter"
              ></v-select>

              <v-select
                  item-text="name"
                  item-value="id"
                  :items="filterArr(objDoctorSpecialization)"
                  v-model="selectedSpecialization"
                  label="Выберите специализацию"
                  outlined
                  flat
                  dense
                  @input="dataFilter"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="objDoctorService.length">
            <v-col
                cols="12"
                md="4"
                sm="6"
                xs="12"
                class="d-flex justify-space-around align-stretch"
                v-for="(item, index) in objDoctorService"
                :key="index"
            >
              <v-card class="media" hover outlined >
                <div class="media-body d-flex align-content-end">
                  <v-row>
                    <v-col cols="12">
                      <h5 class="media-heading" itemprop="description">
                        {{ item.serviceName }}
                      </h5>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        xs="12"
                        class="d-flex justify-space-around align-center"
                    > Даннная услуга оказывается в
                      {{ item.departmentName }}
                    </v-col>
                    <v-col class="d-flex justify-space-around align-center media-price">
                      {{ item.doctorServicePrice || item.servicePrice }}
                    </v-col>
                    <v-col
                        cols="12"
                        class="text-center text-sm-end btn-container d-flex justify-center align-center mb-0"
                    >
                      <v-btn
                          outlined
                          class="btn-order"
                          v-if="item.hiddenLk == 0"
                          color="success"
                          @click="openDrawerFast(item)"
                      >
                        Записаться
                      </v-btn>
                      <v-btn
                          outlined
                          color="red"
                          v-if="item.hiddenLk == 1"
                      >
                        Запись ограничена
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col align="center">
              <p>К сожаланию, данные отсутствуют.</p>
            </v-col>
          </v-row>
        </v-container>
      </div>
    <div class="flex-shrink-0">
      <div class="pagination text-center pb-4 pt-3"
             v-show="objPagination.actual_count >= 2">
          <v-pagination
              v-model="page"
              :length="objPagination.actual_count"
              :prev-icon="icons.mdiMenuLeft"
              :next-icon="icons.mdiMenuRight"
              @input="dataFilter"
          >
          </v-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import * as names from "store/names";
import { mapActions, mapGetters } from "vuex";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";
import { isEmpty } from "lodash";

export default {
  name: "DoctorCardServices",
  data() {
    return {
      selectedSpecialization: null,
      selectedClinic: null,
      loading: false,
      page: 1,
      icons: {
        mdiMenuRight,
        mdiMenuLeft,
      },
      respData: {
        id: 1,
        entity: "card",
      },
    };
  },
  computed: {
    apiAddress() {
      return names.API_ADDRESS;
    },

    filterArr() {
      return function (arr) {
        let res;
        let first = [{ id: 0, name: "Все", specialization: [] }];
        res = first.concat(arr);
        return res;
      };
    },

    ...mapGetters({
      docId: names.DOCTOR_ID,
      objDoctorService: names.OBJ_DOCTOR_CARD_SERVICES,
      objPagination: names.OBJ_DOCTOR_CARD_SERVICE_PAGINATION,
      objDoctor: names.OBJ_DOCTOR_CARD,
      objDoctorSpecialization: names.OBJ_DOCTOR_CARD_SPECIALIZATION,
      objDoctorDepartment: names.OBJ_DOCTOR_CARD_DEPARTMENT,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),

    showResult() {
      return !isEmpty(this.objDoctorService);
    },
  },
  created() {
    this.getService(null, null);
  },
  mounted() {},
  methods: {
    ...mapActions({
      getDoctorService: names.ACTION_GET_DOCTOR_SERVICES,
      selectResultTemplate: names.ACTION_SELECT_RESULT_TEMPLATE,
      selectedDoctorInfo: names.ACTION_SAVE_SELECTED_DOCTOR_INFO,
    }),
    scrollTo(){
      this.$refs.services.scrollIntoView({behavior: 'smooth'})
    },
    async getResult() {
      await this.selectResultTemplate({
        templateName: `DoctorServiceCard`,
        data: this.respData,
      });
    },
    saveSelectDoctorInfo(obj) {
      this.selectedDoctorInfo({
        doctorId: obj.doctorId,
        doctorName: obj.doctorName,
        cityId: this.selectedCity.id,
        serviceName: obj.serviceName,
        serviceId: obj.serviceId,
        doctorServicePrice: obj.doctorServicePrice,
        servicePrice: obj.servicePrice,
        myCoordinates: this.myCoordinates,
        departmentName: obj.departmentName,
        departmentId: obj.departmentId,
        cityPart: obj.cityPart,
        childDoc: obj.childrensMd,
      });
    },

    getService(specializationId, departmentId) {
      this.getDoctorService({
        doctorId: this.docId,
        cityId: this.selectedCity.id,
        page: this.page,
        specializationId: specializationId,
        departmentId: departmentId,
      });
    },

    dataFilter() {
      if (this.selectedSpecialization === 0 && this.selectedClinic === 0) {
        this.getService(null, null);
      }

      if (this.selectedSpecialization !== 0 && this.selectedClinic !== 0) {
        this.getService(this.selectedSpecialization, this.selectedClinic);
      }

      if (this.selectedSpecialization !== 0 && this.selectedClinic === 0) {
        this.getService(this.selectedSpecialization, null);
      }

      if (this.selectedSpecialization === 0 && this.selectedClinic !== 0) {
        this.getService(null, this.selectedClinic);
      }
    },

    openDrawerFast(obj) {
      this.getResult();
      this.saveSelectDoctorInfo(obj);
      this.$drawer.showDrawer(true, "Fast");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.v-pagination__item {
  box-shadow: none !important;
}
.media {
  text-align: left;
  margin-top: 15px;
  overflow: visible;
  padding: 15px;
  border: 1px solid #f2f2f2;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px 5px #e3ecfc;
  }
  &-body {
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  &-price {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }

  &-heading {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #262626;
    padding-bottom: 5px;
  }

  .btn-container {
    @include smaller-phone {
      margin-top: 20px;
    }

    button {
      width: 120px;
      height: 35px;
      font-size: 13px;
      background: initial;
      text-transform: none;
      @include phone-only {
        width: 100px;
        height: 35px;
      }
    }
  }
}
</style>
