<template>
  <div :class="b()">
    <div v-show="showResult">
      <div class="doctor-card">
        <v-breadcrumbs :items="items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                :to="item.to"
                :disabled="item.disabled"
                :text="newText()"

            > {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>

        <doctor-card-navigation :id="id" :name="name" :scroll="scroll"/>
        <div class="doctor-card-wrapper">
          <doctor-card-info></doctor-card-info>
          <doctor-card-services ref="services" />
          <doctor-card-feedback ref="feedback" />
          <doctor-card-education ref="education" />
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div v-show="showNotResult" class="no-found">
      <div class="d-flex justify-center align-center">
        <span> Доктор не найден </span>
      </div>
    </div>
    <button-back-to-top></button-back-to-top>
  </div>
</template>

<script>
import DoctorCardNavigation from "components/doctor-card/DoctorCardNavigation";
import DoctorCardInfo from "components/doctor-card/DoctorCardInfo";
import DoctorCardServices from "components/doctor-card/templates/DoctorCardServices";
import DoctorCardEducation from "components/doctor-card/templates/DoctorCardEducation";
import DoctorCardFeedback from "components/doctor-card/templates/DoctorCardFeedback";
import ButtonBackToTop from "components/partials/ButtonBackToTop";
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";
import { isEmpty } from "lodash";

export default {
  name: "DoctorCardComponent",
  components: { DoctorCardNavigation, DoctorCardInfo, ButtonBackToTop, DoctorCardServices, DoctorCardEducation, DoctorCardFeedback},
  props: ["id", "name"],
  data() {
    return {
      items: [
        {
          text: 'главная',
          disabled: false,
          to: '/',
        },
        {
          text: '',
          disabled: true,
          to: '/doctor/:id/:name',
        }]
    }
  },

  metaInfo(){
    return {
      title: `${this.objDoctor.name + '. ' + this.objDoctor.doctorSpecializationString}`,
      titleTemplate: "%s ← Medveddoc",

      meta: [
        {
        name: "description",
        content: `${'Запись на прием. ' + this.objDoctor.doctorSpecializationString + '. '  + this.objDoctor.name + '. Волгоград'}`,
        },
      ],
    }
  },
  created() {
    this.saveDoctorId(this.id);
  },
  mounted() {},
  computed: {
    ...mapGetters({
      objDoctor: names.OBJ_DOCTOR_CARD,
    }),

    apiAddress() {
      return names.API_ADDRESS;
    },

    showResult() {
      return !isEmpty(this.objDoctor);
    },
    showNotResult() {
      return isEmpty(this.objDoctor);
    },
  },
  methods: {
    ...mapActions({
      saveDoctorId: names.ACTION_SAVE_DOCTOR_ID,
    }),
    newText(){
      this.items[1].text = this.objDoctor.name
    },
    scroll(to){
      this.$refs.[to].scrollTo()
    }
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.v-breadcrumbs {
  padding: 18px 0px ;
}

.DoctorCardComponent {
  min-height: 300px;
  margin-bottom: 70px;
  .no-found {
    height: 100%;
    margin-top: 60px;
  }
}

.doctor-card {
  font-size: 14px;
  line-height: 17px;
  max-width: 930px;
  margin: auto;

  .doctor-card-wrapper {
    border: 10px solid #e3ecfc;
    font-family: $sanspro;
  }
}
</style>
