<template>
  <v-tabs background-color="transparent">
    <v-tab
        class="search-tab-item"
        data-value="services"
        @click="scrolling('services')"
    ><div class="service-icon icon"></div>
      Услуги
    </v-tab>
    <v-tab
        class="search-tab-item"
        data-value="feedback"
        @click="scrolling('feedback')"
    ><div class="feedback-icon icon"></div>
      Отзывы
    </v-tab>
    <v-tab
        class="search-tab-item"
        data-value="education"
        @click="scrolling('education')"

    ><div class="education-icon icon"></div>
      Образование
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "DoctorCardNavigation",
  props: ["id", "name", "scroll"],
  methods:{
    scrolling(to){
      this.scroll(to)
    }
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.doctor-icon {
  mask: url(~icons/doctor.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/doctor.svg) no-repeat center / contain;
}

.service-icon {
  mask: url(~icons/service.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/service.svg) no-repeat center / contain;
}

.feedback-icon {
  mask: url(~icons/clinic.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/feedback.svg) no-repeat center / contain;
}
.education-icon {
  mask: url(~icons/clinic.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/education.svg) no-repeat center / contain;
}
.search-tab-item {
  text-transform: none;
  background: rgba(#e6e4e4, 0.5);
  cursor: pointer;
  text-align: center;
  font-family: $sanspro, sans-serif;
  font-style: normal;
  font-weight: normal;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  color: #000000 !important;
  padding: 10px 5px;
  margin-right: 2px;
  width: 160px;
  letter-spacing: normal;
  @include phone-only {
    width: 32%;
    height: auto;
  }

  .icon {
    pointer-events: none;
    width: 20px;
    height: 13px;
    background-color: #0a0a0a;
  }
}
.v-tab--active {
  color: #0044cc !important;
  background: #e3ecfc !important;
  font-weight: 400 !important;
  .icon {
    background-color: $deep-blue;
  }
}
</style>
